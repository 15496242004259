// @ts-ignore
window.global = window;

(async () => {
  if ('serviceWorker' in navigator) {
    try {
      const registration = await navigator.serviceWorker.register(process.env.NODE_ENV === 'development'
        ? '/firebase-messaging-sw-dev.js'
        : '/firebase-messaging-sw.js', {
        type: 'module',
      });
      console.log({ registration });
      console.log('Service Worker registered with scope:', registration.scope);
      // window.workerRegistration = registration;
    } catch (error) {
      console.error(error);
    }
  }
  import('./polyfills/polyfills').then(() => import('./index'));
})();
